<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 수급업체 -->
          <c-vendor
            name="vendorCd"
            label="수급업체"
            v-model="searchParam.vendorCd"
          ></c-vendor>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 평가구분 -->
          <c-select
            codeGroupCd="VENDOR_EVALUATION_KIND_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="vendorEvaluationKindCd"
            label="평가구분"
            v-model="searchParam.vendorEvaluationKindCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 평가등급 -->
          <c-select
            codeGroupCd="EVALUATION_GRADE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="evaluationGradeCd"
            label="평가등급"
            v-model="searchParam.evaluationGradeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            defaultStart="-6M"
            defaultEnd="6M"
            label="평가기간"
            name="evalTerms"
            v-model="searchParam.evalTerms"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="수급업체 평가 목록"
      tableId="table"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :selection="popupParam.type"
      rowKey="mdmVendorEvaluationId"
      @linkClick="linkClick"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline>
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          <c-btn label="LBLSELECT" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'vendorAssessPop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          plantCd: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        vendorCd: null,
        vendorEvaluationKindCd: null,
        evaluationGradeCd: null,
        evalTerms: [],
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'vendorName',
            field: 'vendorName',
            label: '수급업체',
            style: 'width:200px',
            align: 'left',
            type: 'link',
            sortable: true,
          },
          {
            name: 'vendorEvaluationKindName',
            field: 'vendorEvaluationKindName',
            label: '평가구분',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            label: '상태',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            label: '평가점수',
            child: [
              {
                name: 'totalScore',
                field: 'totalScore',
                style: 'width:100px',
                label: '총점',
                align: 'center',
                sortable: true,
              },
              {
                name: 'score',
                field: 'score',
                style: 'width:100px',
                label: '평점',
                align: 'center',
                sortable: true,
              },
              {
                name: 'evaluationGradeName',
                field: 'evaluationGradeName',
                style: 'width:100px',
                label: '등급',
                align: 'center',
                sortable: true,
              },
            ]
          },
          {
            name: 'evaluationDate',
            field: 'evaluationDate',
            label: '평가기간',
            style: 'width:250px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: '평가결과 종합',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
        height: '500px'
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        isFull: true,
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 50);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.cim.vendor.evaluation.result.list.url;
      // code setting
      if (this.popupParam.plantCd) {
        this.searchParam.plantCd = this.popupParam.plantCd;
      }
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.popupOptions.title = "수급업체 평가 상세"; // 수급업체 평가 상세
      this.popupOptions.param = {
        mdmVendorEvaluationId: row.mdmVendorEvaluationId,
        vendorCd: row.vendorCd,
      };
      this.popupOptions.target = () => import(`${"./vendorAssessDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '수급업체 평가를 선택하세요.', // 수급업체 평가를 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  },
};
</script>
